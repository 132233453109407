import Cookies from "js-cookie";
import { USER_TYPE } from "../constants/constants";
import { IPagination } from "./common.type";
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordValidator = {
  length: /^.{8,}$/,
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  number: /[0-9]/,
  unique: /[^\w]/,
  alphabet: /.*[a-zA-Z].*/,
};
export const isLoggedIn = () => {
  const token = Cookies.get("token");
  return !!token;
};

export const initialMeta: IPagination = {
  current_page: 0,
  from: 1,
  last_page: 1,
  links: [],
  path: "",
  per_page: 15,
  to: 15,
  total: 15,
};

export const getUserType = (planType?: string) => {
  switch (planType) {
    case "Premium":
      return USER_TYPE.PREMIUM;
    case "Enterprise":
      return USER_TYPE.ENTERPRISE;
    default:
      return USER_TYPE.FREE;
  }
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (time % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
};
