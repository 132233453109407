import { createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";
import omitBy from "lodash/omitBy";
import { InterviewService } from "src/api/InterviewService";
import { PersonService } from "src/api/PersonService";
import { IPerson } from "src/modules/dashboard/People/interface/person.type";
import {
  IAnswer,
  IInterview,
  IInterviewChart,
  IOptimize,
  IOptimizeResponse,
  IParticipant,
  IPublicParticipant,
} from "src/modules/interview/interface/interview.type";
import { IPagination, IResponse } from "src/utils/common.type";
import { notify } from "../../components/Toastify/Toastify";
import { setInterviewCount } from "../reducers/user";
setInterviewCount;
const interviewService = new InterviewService();
const personService = new PersonService();

interface IPayload<T> {
  data: T;
  cb?: (response: IResponse<T>) => void;
}

export const createInterview = createAsyncThunk(
  "interview/create",
  async (payload: IPayload<IInterview>, { dispatch }) => {
    const { data, cb } = payload;
    try {
      const formattedData = omitBy(data, (value, key) => {
        return value === null || key === "original_goal";
      });

      const resp = await interviewService.createInterview(formattedData);

      const formattedResponse = { ...data, ...(resp.data?.data || {}) };
      if (cb) {
        cb({ ...resp, data: formattedResponse });
      }

      const state = {
        data: formattedResponse,
        original_goal: data.original_goal,
      };
      dispatch(setInterviewCount());
      return state;
    } catch (err) {
      const { error } = err as IResponse<IInterview>;

      throw error;
    }
  },
);

export const sendInvite = createAsyncThunk(
  "interview/sendInvite",
  async (payload: IPayload<IInterview & { id: number }>) => {
    const { data } = payload;

    try {
      const resp = await interviewService.sendInvite(
        data.id,
        data as IInterview,
      );
      const formattedData = resp?.data?.data?.map(
        (item: IParticipant) => item?.email,
      );
      return formattedData as string[];
    } catch (err) {
      const { error } = err as IResponse<IInterview>;

      throw error;
    }
  },
);

export const getInterview = createAsyncThunk(
  "interview/getInterview",
  async ({ payload, cb }: { payload: number; cb?: () => void }) => {
    try {
      const resp = await interviewService.getInterview(payload);

      const response = resp?.data;

      const { chart } = response;
      const chartValues = (chart?.datasets || []).map(
        (item: { label: string; data: number[] }) => item.data,
      );
      const [sent, completed] = chartValues;

      const formattedChart: IInterviewChart = {
        completed,
        sent,
        totalCompleted: completed.reduce(
          (sum: number, item: number) => sum + item,
          0,
        ),
        totalSent: sent.reduce((sum: number, item: number) => sum + item, 0),
        xLabels: (chart?.labels || []).map((date: string) =>
          format(date, "MMM dd, yyyy"),
        ),
      };

      const formattedPayload = {
        ...response?.data,
        chart: formattedChart,
      } as IInterview;
      if (cb) {
        cb();
      }
      return formattedPayload;
    } catch (err) {
      const { error } = err as IResponse<IInterview>;
      if (cb) {
        cb();
      }
      throw error;
    }
  },
);

export const updateInterview = createAsyncThunk(
  "interview/update",
  async (payload: IPayload<IInterview>) => {
    const { data, cb } = payload;
    try {
      const formattedData = omitBy(data, (value, key) => {
        return value === null || key === "original_goal";
      });
      const resp = await interviewService.updateInterview(
        `${data?.id || ""}`,
        formattedData as IInterview,
      );

      const formattedResponse = { ...data, ...(resp.data?.data || {}) };
      if (cb) {
        cb({ data: formattedResponse });
      }
      return formattedResponse;
    } catch (err) {
      const { error } = err as IResponse<IInterview>;

      throw error;
    }
  },
);

export const startInterView = createAsyncThunk(
  "interview/startInterview",
  async (payload: IPayload<number>) => {
    const { data, cb } = payload;
    try {
      const resp = await interviewService.startInterView(data);
      if (cb) {
        cb({ status: true });
      }
      return { threadId: resp?.data?.thread_id, question: resp?.data?.message };
    } catch (err) {
      const { error } = err as IResponse<IInterview>;

      throw error;
    }
  },
);

export const postInterViewAnswer = createAsyncThunk(
  "interview/postInterViewAnswer",
  async (payload: IPayload<IAnswer>) => {
    const { data, cb } = payload;
    try {
      const resp = await interviewService.postInterViewAnswer(data);
      if (cb) {
        cb({ status: !!resp?.data?.completed });
      }
      return { question: resp?.data?.message, threadId: resp?.data?.thread_id };
    } catch (err) {
      const { error } = err as IResponse<IAnswer>;

      throw error;
    }
  },
);

export const optimizeInterview = createAsyncThunk(
  "interview/optimizeInterview",
  async (
    payload: Omit<IPayload<IOptimize>, "cb"> & {
      cb?: (data: IOptimizeResponse) => void;
    },
  ) => {
    const { data, cb } = payload;
    try {
      const resp = await interviewService.optimizeInterView(data);
      if (cb) {
        cb(resp.data);
      }
      return { data: resp.data?.message, type: data.type };
    } catch (err) {
      const { error } = err as IResponse<IOptimize>;
      throw error;
    }
  },
);

export const getInterviewCandidates = createAsyncThunk(
  "interview/participants",
  async (payload: number) => {
    try {
      const resp = await interviewService.getInterviewCandidates(payload);

      const data = resp?.data as { data: IPerson[]; meta: IPagination };

      return data;
    } catch (err) {
      const { error } = err as IResponse<IPerson>;

      throw error;
    }
  },
);

//public-interview
export const getPublicInterviewDetails = createAsyncThunk(
  "interview/getPublicInterviewDetails",
  async (payload: IPayload<string>) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.getInterviewDetails(data);
      if (cb) {
        cb({});
      }
      return resp.data?.data;
    } catch (err) {
      const { error } = err as IResponse<IPublicParticipant>;

      throw error;
    }
  },
);

export const startParticipantInterView = createAsyncThunk(
  "interview/startPublicInterView",
  async (payload: IPayload<string>) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.startPublicInterview(data);
      if (cb) {
        cb({ status: true });
      }
      return { threadId: resp?.data?.thread_id, question: resp?.data?.message };
    } catch (err) {
      const { error } = err as IResponse<IInterview>;
      if (error) {
        notify({
          message: error.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      throw error;
    }
  },
);

export const postParticipantInterViewAnswer = createAsyncThunk(
  "interview/postPublicInterViewAnswer",
  async (payload: IPayload<IAnswer>) => {
    const { data, cb } = payload;
    try {
      const resp = await personService.postPublicInterViewAnswer(data);
      if (cb) {
        cb({ status: !!resp?.data?.completed });
      }

      return { question: resp?.data?.message };
    } catch (err) {
      const { error } = err as IResponse<IAnswer>;
      if (error) {
        notify({
          message: error.message,
          autoCloseTime: 2000,
          position: "top-right",
          type: "error",
        });
      }
      throw error;
    }
  },
);

export const getInterviewTopParticipants = createAsyncThunk(
  "interview/topCandidates",
  async (payload: number) => {
    try {
      const resp = await interviewService.getTopInterviewParticipants(payload);

      return resp.data?.data || [];
    } catch (error) {}
  },
);
