import {
  // AdminIcon,
  GreenHouseFilledIcon,
  GreenHouseIcon,
  // IcimsIcon,
  IntegrateIcon,
  IntegrationFillIcon,
  InterViewIcon,
  InterviewFillIcon,
  // PaylocityIcon,
  PeopleGroupFillIcon,
  PeopleIcon,
  SettingsFillIcon,
  SettingsIcon,
} from "src/assets/icons";

export const sidebarMenu = [
  {
    title: "Interviews",
    route: "/interview",
    icon: <InterViewIcon />,
    tabSelectedIcon: <InterviewFillIcon />,
  },
  {
    title: "Integrations",
    route: "/integration",
    icon: <IntegrateIcon />,
    tabSelectedIcon: <IntegrationFillIcon />,
  },
  {
    title: "People",
    route: "/people",
    matchingRoute: "/group",
    icon: <PeopleIcon />,
    tabSelectedIcon: <PeopleGroupFillIcon />,
  },
  // {
  //   title: "Administration",
  //   route: "/administration",
  //   icon: <AdminIcon />,
  // },
  {
    title: "Settings",
    route: "/settings",
    icon: <SettingsIcon />,
    tabSelectedIcon: <SettingsFillIcon />,
  },
];

export const STATUS = {
  ACTIVE: "active",
  OPENED: "opened",
  SENT: "sent",
};

export enum INTERVIEW_STATUS {
  NOT_STARTED = "notStarted",
  IN_PROGRESS = "inProgress",
  COMPLETED = "completed",
}

export const INTEGRATION_TABS = [
  { label: "Available", name: "available" },
  { label: "Connected", name: "connected" },
];

export const SETTINGS_TABS = [
  { label: "Overview", value: "overview" },
  { label: "Payment Methods", value: "payment_methods" },
  { label: "Billing History", value: "billing_history" },
  { label: "Usage Limits", value: "usage_limits" },
  { label: "Pricing", value: "pricing" },
];

export const INTEGRATION_OPTIONS = [
  {
    name: "greenHouse",
    label: "Greenhouse",
    description:
      "Automatically pull in Job Posts, Jobs, and Candidates to create more precise interviews.",
    icon: <GreenHouseFilledIcon />,
    modalData: {
      title: "Greenhouse Integration",
      subTitle:
        "The integration grants Evaluait access to your jobs, candidates, interviews, and other data via API. Those items will be automatically synced into Evaluait.",
      icon: <GreenHouseIcon />,
      description: "Enjoy the immediate benefits of automation:",
      benefits: [
        "Job interview creation",
        "Flexible interview scheduling",
        "Candidate profile generation",
        "Candidate categorization into correct groups",
      ],
    },
  },
  // {
  //   name: "paylocity",
  //   label: "Paylocity",
  //   description:
  //     "Automatically pull in Job Posts, Jobs, and Candidates to create more precise interviews.",
  //   icon: <PaylocityIcon />,
  //   modalData: {
  //     title: "Paylocity Integration",
  //     subTitle:
  //       "The integration grants Evaluait access to your jobs, candidates, interviews, and other data via API. Those items will be automatically synced into Evaluait.",
  //     icon: <GreenHouseIcon />,
  //     description: "Enjoy the immediate benefits of automation:",
  //     benefits: [
  //       "Job interview creation",
  //       "Flexible interview scheduling",
  //       "Candidate profile generation",
  //       "Candidate categorization into correct groups",
  //     ],
  //   },
  // },
  // {
  //   name: "icims",
  //   label: "iCIMS",
  //   description:
  //     "Automatically pull in Job Posts, Jobs, and Candidates to create more precise interviews.",
  //   icon: <IcimsIcon />,
  //   modalData: {
  //     title: "iCIMS Integration",
  //     subTitle:
  //       "The integration grants Evaluait access to your jobs, candidates, interviews, and other data via API. Those items will be automatically synced into Evaluait.",
  //     icon: <GreenHouseIcon />,
  //     description: "Enjoy the immediate benefits of automation:",
  //     benefits: [
  //       "Job interview creation",
  //       "Flexible interview scheduling",
  //       "Candidate profile generation",
  //       "Candidate categorization into correct groups",
  //     ],
  //   },
  // },
];

export enum USER_TYPE {
  FREE = "free",
  PREMIUM = "premium",
  ENTERPRISE = "enterprise",
}

export const INTERVIEW_TOTAl_TIME = 30 * 60;
export const INTERVIEW_QUESTION_TIME = 3 * 60;
